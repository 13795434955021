<template>
  <div class="total-operation">
    <div class="page-title">营收分析</div>
    <div class="page-content">
      <div
        class="content-item-box"
        v-for="(item, index) in templateList"
        :key="index"
      >
        <div class="content-item" v-for="(v, i) in item" :key="i">
          <!-- <div class="item-num">{{ resData[v.key] }}</div> -->
          <div class="item-num" :class="{ red: numFilter(v.key) < 0 }">
            {{ numFilter(v.key) }}<span v-if="v.unit">{{ v.unit }}</span>
          </div>
          <div class="item-txt">{{ v.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    templateList: [
      [
        {
          label: "运营部应缴总额(元)",
          key: "totalRentFee"
        },
        {
          label: "总营收(元)",
          key: "totalIncome"
        },
        {
          label: "金额差(元)",
          key: "totalIncome,totalRentFee"
        },
        {
          label: "应缴利润率",
          unit: "%",
          key: "profitRate"
        }
      ],
      [
        {
          label: "线下应收金额(元)",
          key: "offlinePayableAmount"
        },
        {
          label: "线下实收金额(元)",
          key: "offlinePayAmount"
        },
        {
          label: "金额差(元)",
          key: "offlinePayAmount,offlinePayableAmount"
        },
        {
          label: "百分比",
          unit: "%",
          key: "percent"
        }
      ]
    ]
  }),

  methods: {
    numFilter(key) {
      let arr = key.split(",").map(item => this.data[item]);
      if (arr.length === 1 && arr[0] !== null) {
        return arr[0];
      } else {
        if (arr[0] || arr[1]) {
          return (arr[0] - arr[1]).toFixed(2);
        } else {
          return "--";
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.total-operation {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 14px;
  overflow: hidden;
}

.page-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding-left: 20px;
  background-color: #003399;
  color: #fff;
  font-size: 14px;
}

.page-content {
  width: 100%;
  padding: 0 12px;
  background-color: #fff;

  .content-item-box {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &:first-child {
      border-bottom: 1px solid #80ccff;
    }

    &::before {
      display: block;
      content: "";
      width: 0;
      height: 60%;
      border-right: 1px dashed #cbebfe;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%);
    }

    &::after {
      display: block;
      content: "";
      width: 96%;
      height: 0;
      border-bottom: 1px dashed #cbebfe;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

    .content-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 50%;
      height: 78px;
      padding: 12px;
      color: #333;

      .item-num {
        height: 20px;

        &.red {
          color: #ff0052;
        }
      }

      .item-txt {
        font-size: 12px;
      }
    }
  }
}
</style>
