<template>
  <div class="total-revenue">
    <div class="page-selector">
      <div class="selector-item location-selector">
        <slot>
          <div class="selector-item-box" @click="show = true">
            <div class="selector-icon"></div>
            <div class="selector-txt">{{ pickerTxt }}</div>
          </div>
        </slot>
      </div>

      <div class="selector-item date-selector" @click="showDate = true">
        <div class="selector-txt">{{ currentDate | dataFilter }}</div>
        <div class="selector-icon"></div>
      </div>
    </div>
    <div class="page-content">
      <div class="page-chart-box">
        <div class="page-chart" ref="pageChart"></div>
        <div class="chart-txt">
          <p class="num">{{ chartData.totalIncome }}</p>
          <p class="txt">总收入(元)</p>
        </div>
      </div>
      <div class="page-info">
        <div
          class="info-item"
          v-for="(item, index) in templateList"
          :key="index"
        >
          <div class="item-left">
            <div
              class="item-icon"
              :style="{ backgroundColor: item.color }"
            ></div>
            <div class="item-tittle">{{ item.label }}</div>
          </div>
          <div class="item-right">
            <div class="item-content">
              {{ contentFilter(item.key) ? contentFilter(item.key) : "--" }}
              {{ item.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" round>
      <van-picker
        show-toolbar
        :title="pickerTitle"
        :columns="tenantColumns"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showDate" position="bottom" round>
      <van-datetime-picker
        type="year-month"
        title="选择年月"
        :value="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onConfirmDate"
      />
    </van-popup>
  </div>
</template>

<script>
// import echarts from "echarts";
export default {
  props: {
    pickerTitle: {
      type: String,
      default: ""
    },
    tenantColumns: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    pickerTxt: "全部",
    templateList: [
      {
        label: "实际车位 / 总车位数",
        key: "actualPsNum,totalPsNum",
        color: "#0033993",
        unit: "个"
      },
      {
        label: "单车位收入",
        key: "unitPsIncome",
        color: "#fb6b67",
        unit: "元"
      },
      {
        label: "线上收入",
        key: "onlineIncome",
        color: "#ffb6f2",
        unit: "元"
      },
      {
        label: "预缴收入",
        key: "prepayAmount",
        color: "#5acc8e",
        unit: "元"
      },
      {
        label: "线下二维码",
        key: "offlineQrcodeIncome",
        color: "#fcc274",
        unit: "元"
      },
      {
        label: "现金收入",
        key: "cashAmount",
        color: "#7ec8f8",
        unit: "元"
      }
    ],
    show: false,
    showDate: false,
    columns: [
      {
        values: ["沙园街道", "素社直街"]
      },
      {
        values: ["怡安路", "沙园大街", "曹芳横一巷公厕旁", "晓园北路、晓园路"]
      }
    ],

    minDate: new Date(2010, 0, 1),
    maxDate: new Date(),
    currentDate: new Date()
  }),

  watch: {
    // 监听数据更新Echart
    chartData(newV) {
      if (newV) {
        this.$utils.refreshDoughnutChart(this.myChart, newV);
      }
    }
  },
  methods: {
    // 租户选择器确定函数回调
    onConfirm(value, index) {
      // 点击确定，关闭弹窗，回调对象
      console.log(`Value: ${value}, Index: ${index}`, value);
      this.pickerTxt = value.text;
      this.show = false;
      this.$emit("confirm", { tenant: value });
    },

    // 日期选择器确定函数回调
    onConfirmDate(value) {
      this.currentDate = value;
      this.showDate = false;
      this.$emit("confirm", { date: value });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },

    contentFilter(key) {
      /**
       * 根据key值获取相应字段的数值
       * 如有多个key，以,分隔
       */
      let arr = key.split(",").map(item => this.chartData[item]);
      return arr.join("/");
    }
  },

  filters: {
    dataFilter(val) {
      let date = new Date(val);
      return `${date.getFullYear()}年${date.getMonth() + 1}月`;
    }
  },

  mounted() {
    // 创建Echart实例
    if (!this.myChart) {
      this.myChart = this.$utils.initEchart(this.$refs.pageChart);
    }
  },

  beforeDestroy() {
    if (this.myChart) {
      this.myChart.dispose();
    }
  }
};
</script>

<style lang="less" scoped>
.total-revenue {
  width: 100%;
  margin-bottom: 14px;
}
.page-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;

  .selector-item {
    display: flex;
    align-items: center;
  }

  .location-selector {
    flex: 0 0 56%;
    overflow: hidden;
  }

  .selector-item-box {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .selector-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selector-icon {
    width: 0;
    height: 0;
    border-top: 6px solid #000;
    border-left: 6px solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 6px solid transparent;
    margin: 0 2px;
  }
}

.page-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 0 12px;

  .page-chart-box {
    width: 100%;
    height: 230px;
    position: relative;

    .page-chart {
      width: 100%;
      height: 230px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px dashed #e4e4e4;
    }

    .chart-txt {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      .num {
        font-size: 16px;
        color: #333;
      }
    }
  }
}

.page-info {
  width: 100%;

  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 0 8px;
    border-bottom: 1px dashed #f2f2f2;

    &:last-child {
      border: 0;
    }

    .item-left {
      display: flex;
      align-items: center;
    }

    .item-icon {
      width: 4px;
      height: 12px;
      background-color: #333;
      margin-right: 8px;
    }
  }
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 11111;
}
</style>
