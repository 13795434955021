<template>
  <div class="page-box">
    <div class="scroll-box">
      <div class="total-revenue-box">
        <total-revenue
          :pickerTitle="'请选择租户'"
          :tenantColumns="tenantColumns"
          :chartData="totalRevenueData"
          @confirm="totalRevenueConfirm"
        >
          <slot></slot>
        </total-revenue>
      </div>
      <div class="parking-operation-box" v-if="showParkingIncome">
        <div class="page-title">车场营收分析</div>
        <div class="page-content">
          <div class="content-item-box">
            <div
              class="content-item"
              v-for="(item, index) in itemDomList"
              :key="index"
              @click="
                gotoPage('RevenueDetail', {
                  data: parkingIncomeData,
                  template: item
                })
              "
            >
              <div class="item-trend">
                <div class="trend-item">
                  <span class="icon-up" :style="{ color: '#008000' }"></span
                  >{{ parkingIncomeData[item.up] }}
                </div>
                <div class="trend-item middle">
                  <span class="icon-down" :style="{ color: '#ff0000' }"></span
                  >{{ parkingIncomeData[item.down] }}
                </div>
                <div class="trend-item">
                  <span class="flat"></span>{{ parkingIncomeData[item.flat] }}
                </div>
              </div>
              <div class="item-title">
                {{ item.label }}
                <span
                  class="title-icon"
                  v-if="item.extraIcon"
                  @click.stop="item.showTips = !item.showTips"
                  >i</span
                >
              </div>
              <div class="item-tips" v-show="item.showTips">
                线上收入+线下二维码+现金收入
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="total-operation-box">
        <total-operation :data="totalOperationData"></total-operation>
      </div>
      <div class="monthly-operation-box">
        <monthly-operation
          :data="monthlyOperationData"
          @confirm="monthlyOperationConfirm"
        ></monthly-operation>
      </div>
      <div class="annual-operation-box">
        <annual-rate
          :chartData="annualRateData"
          @confirm="AnnualRateConfirm"
        ></annual-rate>
      </div>
    </div>
  </div>
</template>

<script>
import TotalRevenue from "../components/Operation/TotalRevenue.vue";
import TotalOperation from "../components/Operation/TotalOperation.vue";
import MonthlyOperation from "../components/Operation/monthlyOperation.vue";
import AnnualRate from "../components/Operation/annualRate.vue";

export default {
  components: {
    TotalRevenue,
    TotalOperation,
    MonthlyOperation,
    AnnualRate
  },

  props: {
    showParkingIncome: {
      type: Boolean,
      default: false
    },

    locationObj: {
      type: Object,
      default: () => ({})
    }
  },

  watch: {
    locationObj({ tenantId, parkingId }) {
      // 当收到具体租户号及车场号，则按实际查询
      // 若locationObj传空对象，则查全部
      tenantId && (this.pageReq.tenantId = tenantId);
      parkingId && (this.pageReq.parkingId = parkingId);

      if (!tenantId && !parkingId) {
        this.pageReq.tenantId = this.tenantColumns[0]["id"];
        this.pageReq.parkingId = null;
      }

      this.reqPageData();
    }
  },

  data: () => ({
    itemDomList: [
      {
        label: "车场总营收",
        key: "totalIncomeGrowthRate",
        up: "totalIncomeIncreaseNum",
        down: "totalIncomeDecreaseNum",
        flat: "totalIncomeEqNum"
      },
      {
        label: "单车位营收",
        key: "unitPsGrowthRate",
        up: "unitPsIncreaseNum",
        down: "unitPsDecreaseNum",
        flat: "unitPsEqNum"
      },
      {
        label: "单车位预缴收入",
        key: "unitPsPrepayGrowthRate",
        up: "unitPsPrepayIncreaseNum",
        down: "unitPsPrepayDecreaseNum",
        flat: "unitPsPrepayEqNum"
      },
      {
        label: "单车位临停收入",
        key: "tempParkIncomeGrowthRate",
        up: "tempParkIncomeIncreaseNum",
        down: "tempParkIncomeDecreaseNum",
        flat: "tempParkIncomeEqNum",
        extraIcon: true,
        showTips: false
      }
    ],
    pageReq: {}, // 请求对象

    // --总营收图表组件--
    tenantColumns: [], // 租户列表
    totalRevenueData: {}, // 组件数据

    // --车场营收分析--
    parkingIncomeData: {},

    // --营收分析--
    totalOperationData: {},

    // --月营收分析--
    monthlyOperationData: {},

    // --年同比--
    annualRateData: {},
    annualRateType: 1
  }),

  methods: {
    gotoPage(name, params) {
      this.$router.push({ name, params });
    },

    // -----总营收图表组件-----
    // 页面初始化函数
    async totalRevenueInit() {
      // 获取租户信息
      let { data } = await this.$api.getTenant();

      // 添加 "全部" 选项
      let total = {
        text: "全部"
      };
      data.forEach(item => {
        item.text = item.name;
        if (!total.id) {
          total.id = item.id;
        } else {
          total.id += "," + item.id;
        }
      });

      data.unshift(total);
      this.tenantColumns = data;

      // 初始请求信息为： 租户--全部 时间--现在
      this.pageReq.tenantId = total.id;
      this.pageReq.date = new Date();
      this.pageReq.lastDate = new Date();
    },

    // 请求获取页面数据
    async reqTotalRevenueData({ tenantId, date, parkingId = null }) {
      let reqDate = new Date(date);

      let { data } = await this.$api.getTotalRevenueData(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1,
        parkingId
      );

      this.totalRevenueData = data;
    },

    // 选择器点击确定回调函数
    totalRevenueConfirm(obj) {
      obj["tenant"] && (this.pageReq.tenantId = obj["tenant"]["id"]);
      obj["date"] && (this.pageReq.date = obj["date"]);
      console.log("总营收图表请求数据 ==>", this.pageReq);

      this.reqPageData();
    },

    // --营收分析--
    // 请求页面数据
    async reqTotalOperationData({ tenantId, date, parkingId = null }) {
      let reqDate = new Date(date);

      let { data } = await this.$api.getTotalOperation(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1,
        parkingId
      );

      // console.log("--营收分析--请求页面数据 ==>", data);

      this.totalOperationData = data;
    },

    // --车场营收分析--
    // 请求数据
    async reqParkingIncome({ tenantId, date }) {
      let reqDate = new Date(date);
      let { data } = await this.$api.getParkingIncome(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1
      );
      console.log("--车场营收分析--请求页面数据 ==>", data);
      this.parkingIncomeData = data;
    },

    // --月营收对比分析--
    // 请求数据
    async reqMonthlyOperationData({
      tenantId,
      date,
      lastDate,
      parkingId = null
    }) {
      let reqDate = new Date(date);
      let reqLastDate = new Date(lastDate);

      let { data } = await this.$api.getMonthlyOperationData(
        tenantId,
        reqDate.getFullYear(),
        reqDate.getMonth() + 1,
        reqLastDate.getFullYear(),
        reqLastDate.getMonth() + 1,
        parkingId
      );
      // console.log(data);
      this.monthlyOperationData = data;
    },

    // 月营收日期选择器点击确定回调函数
    async monthlyOperationConfirm(val) {
      console.log(val);
      val && (this.pageReq.lastDate = val);

      this.reqMonthlyOperationData(this.pageReq);
    },

    async reqAnnualRateDate({ tenantId, date, parkingId = null }, type) {
      let reqDate = new Date(date);
      let { data } = await this.$api.getAnnualRate(
        tenantId,
        reqDate.getFullYear(),
        type,
        parkingId
      );

      this.annualRateData = data;
      console.log("请求折线图信息 ==>", data);
    },

    AnnualRateConfirm(val) {
      this.annualRateType = val;

      this.reqAnnualRateDate(this.pageReq, this.annualRateType);
    },

    async reqPageData() {
      this.reqTotalRevenueData(this.pageReq);
      this.reqTotalOperationData(this.pageReq);
      if (this.showParkingIncome) {
        this.reqParkingIncome(this.pageReq);
      }
      this.reqMonthlyOperationData(this.pageReq);
      this.reqAnnualRateDate(this.pageReq, this.annualRateType);
    },

    async pageInit() {
      await this.totalRevenueInit();

      this.reqPageData();
    }
  },

  mounted() {
    this.pageInit();
  }
};
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
  overflow: hidden;
  position: relative;
}

.scroll-box {
  width: calc(100% + 20px);
  height: 100%;
  padding-left: 12px;
  padding-right: 32px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  right: -20px;
}

.parking-operation-box {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 14px;
  // overflow: hidden;

  .page-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    padding-left: 20px;
    background-color: #003399;
    color: #fff;
    font-size: 14px;
  }

  .page-content {
    width: 100%;
    padding: 10px 12px 0;
    background-color: #fff;
  }

  .content-item-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    position: relative;

    &::before {
      display: block;
      width: 96%;
      height: 0;
      border-bottom: 1px dashed #cbebfe;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
    }

    &:after {
      display: block;
      content: "";
      width: 0;
      height: 60%;
      border-right: 1px dashed #cbebfe;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%);
    }

    .content-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 50%;
      height: 78px;
      padding: 14px;
      color: #1e1e1e;
      position: relative;
    }

    .item-trend {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .trend-item {
        display: flex;
        align-items: center;
        font-size: 14px;

        &.middle {
          margin: 0 10px;
        }
      }

      .flat {
        display: block;
        width: 8px;
        height: 2px;
        margin-right: 2px;
        background-color: #0b0ba2;
      }
    }

    .item-title {
      display: flex;
      align-items: center;
      font-size: 12px;

      .title-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 4px;
        width: 14px;
        height: 14px;
        margin-left: 2px;
        border: 1px solid rgba(7, 17, 27, 0.4);
        border-radius: 50%;
      }
    }

    .item-tips {
      padding: 6px 12px;
      // border: 1px solid #999;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: -2px -2px 6px #ccc;
      font-size: 12px;
      white-space: nowrap;
      position: absolute;
      bottom: -16px;
      right: 0;
    }
  }
}
</style>
