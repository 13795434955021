<template>
  <div class="monthly-operation">
    <div class="page-title">月营收对比分析</div>
    <div class="page-content">
      <div class="page-table">
        <table cellspacing="1">
          <tr>
            <th></th>
            <th>
              <div class="table-selector" @click="showDate = true">
                {{ currentDate | yaerMonthFilter }}营收
                <span class="title-icon"></span>
              </div>
            </th>
            <th>当月营收</th>
            <th>金额差</th>
            <th>增长率</th>
          </tr>
          <tr v-for="(item, index) in tabColumns" :key="index">
            <th>{{ item.label }}</th>
            <td :class="{ red: tdFilter(item.td1) < 0 }">
              {{ tdFilter(item.td1) }}
            </td>
            <td :class="{ red: tdFilter(item.td2) < 0 }">
              {{ tdFilter(item.td2) }}
            </td>
            <td :class="{ red: tdFilter(item.td3) < 0 }">
              {{ tdFilter(item.td3) }}
            </td>
            <td :class="{ red: tdFilter(item.td4) < 0 }">
              {{ tdFilter(item.td4) }}%
            </td>
          </tr>
        </table>
      </div>
    </div>

    <van-popup v-model="showDate" position="bottom" round>
      <van-datetime-picker
        type="year-month"
        title="选择年月"
        :value="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  filters: {
    yaerMonthFilter(val) {
      let date = new Date(val);
      return `${date.getFullYear()}-${
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1
      }`;
    }
  },
  data: () => ({
    showDate: false,
    minDate: new Date(2010, 0, 1),
    maxDate: new Date(),
    currentDate: new Date(),

    tabColumns: [
      {
        label: "单车位营收",
        td1: "unitPsIncome2",
        td2: "unitPsIncome1",
        td3: "unitPsIncome1,unitPsIncome2",
        td4: "unitPsGrowthRate"
      },
      {
        label: "单车位线上收入",
        td1: "unitPsOnlineIncome2",
        td2: "unitPsOnlineIncome1",
        td3: "unitPsOnlineIncome1,unitPsOnlineIncome2",
        td4: "unitPsOnlineGrowthRate"
      },
      {
        label: "单车位线下二维码",
        td1: "unitPsOfflineQrcodeIncome2",
        td2: "unitPsOfflineQrcodeIncome1",
        td3: "unitPsOfflineQrcodeIncome1,unitPsOfflineQrcodeIncome2",
        td4: "unitPsOfflineQrcodeGrowthRate"
      },
      {
        label: "单车位预缴收入",
        td1: "unitPsPrepayAmount2",
        td2: "unitPsPrepayAmount1",
        td3: "unitPsPrepayAmount1,unitPsPrepayAmount2",
        td4: "unitPsPrepayGrowthRate"
      },
      {
        label: "单车位现金收入",
        td1: "unitPsCashAmount2",
        td2: "unitPsCashAmount1",
        td3: "unitPsCashAmount1,unitPsCashAmount2",
        td4: "unitPsCashGrowthRate"
      },
      {
        label: "总营收",
        td1: "totalIncome2",
        td2: "totalIncome1",
        td3: "totalIncome1,totalIncome2",
        td4: "totalIncomeGrowthRate"
      }
    ]
  }),
  methods: {
    onConfirm(val) {
      console.log(this.data);

      this.currentDate = val;
      this.showDate = false;
      this.$emit("confirm", val);
    },
    tdFilter(key) {
      let arr = key.split(",").map(item => this.data[item]);
      if (arr.length === 1 && arr[0] !== null) {
        return arr[0];
      } else {
        console.log(arr);

        if (arr[0] || arr[1]) {
          return (arr[0] - arr[1]).toFixed(2);
        } else {
          return "--";
        }
      }
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    }
  }
};
</script>

<style lang="less" scoped>
.monthly-operation {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 14px;
  overflow: hidden;
}

.page-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding-left: 20px;
  background-color: #003399;
  color: #fff;
  font-size: 14px;
}

.page-content {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  overflow: hidden;
}

.page-table {
  width: 100%;
  padding-bottom: 8px;
  overflow-x: scroll;

  table {
    background-color: #ccebff;
    // border: 1px solid #ccebff;

    tr {
      th {
        height: 32px;
        min-width: 70px;
        padding: 6px 10px;
        background-color: #e6f5ff;
        font-size: 12px;
        white-space: nowrap;
        text-align: left;
      }

      td {
        padding: 6px 10px;
        background-color: #fff;
        font-size: 12px;

        &.red {
          color: red;
        }
      }

      .table-selector {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;

        .title-icon {
          width: 0;
          height: 0;
          border-top: 6px solid #000;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 0;
          margin-left: 4px;
        }
      }
    }
  }
}
</style>
