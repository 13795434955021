<template>
  <div class="annual-operation">
    <div class="page-title">
      <span class="title-txt">
        月营收年同比
      </span>
      <div class="title-selector" @click="show = true">
        {{ pickerText }}
        <span class="selector-icon"></span>
      </div>
    </div>
    <div class="page-content">
      <div class="echart-box" ref="echartBox"></div>
    </div>

    <van-popup v-model="show" position="bottom" round>
      <van-picker
        show-toolbar
        title="请选择查看类型"
        :columns="columns"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    show: false,
    columns: [
      {
        text: "总营收",
        value: 1
      },
      {
        text: "单车位营收",
        value: 2
      },
      { text: "单车位线上营收", value: 3 },
      { text: "单车位线下二维码营收", value: 4 },
      { text: "单车位现金收入", value: 5 },
      { text: "单车位预缴收入", value: 6 }
    ],
    pickerText: "总营收"
  }),
  watch: {
    chartData(newV) {
      this.initChart(newV);
    }
  },
  methods: {
    onConfirm(item) {
      console.log(item);
      this.pickerText = item.text;
      this.show = false;
      this.$emit("confirm", item.value);
    },

    initChart(data) {
      if (!this.myChart) {
        this.myChart = this.$utils.initEchart(this.$refs.echartBox);
      }

      console.log(this.myChart);
      let nowDate = new Date();
      this.$utils.refreshRateChart(
        this.myChart,
        nowDate.getFullYear() - 1 + "",
        data[nowDate.getFullYear() - 1 + ""],
        nowDate.getFullYear() + "",
        data[nowDate.getFullYear() + ""]
      );
    }
  },

  beforeDestroy() {
    if (this.myChart) {
      this.myChart.dispose();
    }
  }
};
</script>

<style lang="less" scoped>
.annual-operation {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 14px;
  overflow: hidden;
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  padding-left: 20px;
  padding-right: 10px;
  background-color: #003399;
  color: #fff;
  font-size: 14px;

  .title-selector {
    display: flex;
    align-items: center;

    .selector-icon {
      width: 0;
      height: 0;
      border-top: 6px solid #fff;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 0;
      margin-left: 6px;
    }
  }
}

.page-content {
  height: 260px;
  width: 100%;
  padding-bottom: 6px;
  background-color: #fff;

  .echart-box {
    width: 100%;
    height: 100%;
  }
}
</style>
